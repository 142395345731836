import { getContext, setContext } from 'svelte'
import { writable, type Writable } from 'svelte/store'
// Make the modal state available to all child components via the context API
// Variables need to be writable stores in order to be reactive

// Modal state
export function set_modal_state() {
	const is_modal_open = writable<boolean>(false)
	setContext('modal', is_modal_open)
}

export function get_modal_state() {
	return getContext<Writable<boolean>>('modal')
}

// Mobile nav state
export function set_mobile_nav_state() {
	const is_mobile_nav_open = writable<boolean>(false)
	setContext('nav', is_mobile_nav_open)
}

export function get_mobile_nav_state() {
	return getContext<Writable<boolean>>('nav')
}

// Navigation sub-menu state
export function set_nav_submenu_state() {
	const is_nav_submenu_open = writable<boolean>(false)
	setContext('sub', is_nav_submenu_open)
}

export function get_nav_submenu_state() {
	return getContext<Writable<boolean>>('sub')
}

// // Mobile sub-menu state
// export function set_mobile_submenu_state() {
// 	const is_mobile_submenu_open = writable<boolean>(false)
// 	setContext('mobile-sub', is_mobile_submenu_open)
// }

// export function get_mobile_submenu_state() {
// 	return getContext<Writable<boolean>>('mobile-sub')
// }
